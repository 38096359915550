// import s from "./style.module.css"; // Import Footer CSS file
import { AboutContainer } from "../about_container/about_container";

export function About() {
	return (
		<div>
			<AboutContainer />
		</div>
	);
}
