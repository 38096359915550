import s from "./style.module.css"; // Import Header CSS file
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

export function Header() {
	const [path, setPath] = useState("");

	useEffect(() => {
		console.log(window.location.pathname);
		setPath(window.location.pathname);
	}, [path]);

	return (
		<div className={`${s.header} `}>
			<nav className={` navbar navbar-expand-sm pb-0 w-100 `}>
				<a className={`navbar-brand mx-4 py-0 `} href="/">
					<img src={logo} alt="VelocityTax Logo " width="40" height="40" />
					<span className={`${s.text} ps-2`}>
						<b>VelocyTax</b>
					</span>
				</a>

				<button
					className="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon "></span>
				</button>

				<div
					className=" collapse navbar-collapse  justify-content-between"
					id="navbarSupportedContent"
				>
					<div className={`col-4 `}>
						<ul className={`navbar-nav nav-tabs border-bottom-0 menu`}>
							<li className={` nav-item `}>
								<Link
									className={`nav-link ${s.link} ${
										path === "/" ? "active" : ""
									} `}
									to="/"
									onClick={() => {
										setPath("/");
									}}
								>
									<span className={`${s.text} `}>Home</span>
								</Link>
							</li>
							<li className={`nav-item `}>
								<Link
									className={`nav-link ${s.link} ${
										path === "/about" ? "active" : ""
									}`}
									to="/about"
									onClick={() => {
										setPath("/about");
									}}
								>
									<span className={`${s.text} `}>About Us</span>
								</Link>
							</li>
							{/* <li className={`nav-item `}>
								<Link
									className={`nav-link ${s.link} ${
										path === "/contact" ? "active" : ""
									}`}
									to="/contact"
									onClick={() => {
										setPath("/contact");
									}}
								>
									<span className={`${s.text} `}>Contact</span>
								</Link>
							</li> */}

							{/* <li className={`nav-item dropdown `}>
								<p
									className={`nav-link dropdown-toggle ${s.link}`}
									id="navbarDropdown"
									role="button"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									<span className={`${s.text} `}>About</span>
								</p>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown">
									<Link className="dropdown-item" to="/blogs">
										Blogs
									</Link>
									<Link className="dropdown-item" to="/contact">
										Contact us
									</Link>
									<Link className="dropdown-item" to="/services">
										Services
									</Link>
								</div>
							</li> */}
						</ul>
					</div>

					<div className={`col-4 d-flex flex-row-reverse me-3`}>
						<div className={`${s.text} px-4`}>
							<b>Coming Soon!</b>
						</div>
						{/* <ul className="navbar-nav  ">
							<li className={`d-flex justify-content-center `}>
								<button type="button" className={`${s.login} `} href="/login">
									<span>Login</span>
								</button>
							</li>
							<li>
								<button type="button" className={`  ${s.btn} `} href="/signup">
									<span>Sign up</span>
								</button>
							</li>
						</ul> */}
					</div>
				</div>
			</nav>
		</div>
	);
}
