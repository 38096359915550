import s from "./style.module.css";
import homeImage1 from "../../assets/home-img-1.png";

export function HomeContainer1() {
	return (
		<div className={`${s.container1}`}>
			<div className={`container`}>
				<div id="container1" className={`row  justify-content-md-center `}>
					<div id="block1" className={`col-md-6  ${s.block1}`}>
						<div className={`${s.text1}`}>
							<h1>Book Your Way, Set Your Price, Keep Your Profit!</h1>
						</div>
						<div className={`${s.text2} pt-3`}>
							<b>VelocyTax:</b> Empowering Riders and Drivers with Choice
							<br />- Where Your Journey is Driven by Your Decisions and
							Desires.
						</div>
					</div>
					<div id="block2" className={` col-md-6 col-sm-12 ${s.block2}`}>
						<img
							src={homeImage1}
							alt="VelocityTax app "
							className={`${s.image1} `}
						></img>
					</div>
				</div>
			</div>
		</div>
	);
}
