import s from "./style.module.css";
import homeImage2 from "../../assets/ride-img-1.png";
import img1 from "../../assets/home-img-6.png";
import img2 from "../../assets/home-img-4.png";
import img3 from "../../assets/home-img-5.png";

export function HomeContainer2() {
	return (
		<div className={`${s.container2}`}>
			<div className={``}>
				<div id="container4" className={`row gx-0 justify-content-md-center `}>
					<div id="block1" className={` col-md-7 col-sm-12 ${s.block2}`}>
						<img
							src={homeImage2}
							alt="VelocityTax app "
							className={`${s.image1} `}
						></img>
					</div>
					<div id="block2" className={`col-md-5 ps-4`}>
						<div className="">
							<div className={`${s.text1}`}>
								<h1>
									Your Journey Awaits with{" "}
									<span style={{ color: "#0498B1" }}>
										Your Terms, Your Price!
									</span>
								</h1>
							</div>
							<div className={`${s.text2}`}>
								Tailor your journey with VelocyTax by setting your price.
								Request rides with preferred fare, ensuring budget-friendly
								travel. -Ride your way!
							</div>
							<div className={`py-4`}>
								<div className={`d-flex  justify-content-center py-4`}>
									<div className={`col`}>
										<div>
											<img src={img2} alt="Rupees" />
										</div>
										<div className={`${s.textContainer}`}>Set your Price</div>
									</div>
									<div className={`col`}>
										<div>
											<img src={img1} alt="Rupees" />
										</div>
										<div className={`${s.textContainer}`}>Set your Price</div>
									</div>
									<div className={`col`}>
										<div>
											<img src={img3} alt="Rupees" />
										</div>
										<div className={`${s.textContainer}`}>Set your Price</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
