// import logo from './logo.svg';
import "./App.css";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { Outlet } from "react-router-dom";
import { PreFooter } from "./components/pre_footer/pre_footer";

function App() {
	return (
		<div className="App">
			<Header />
			<Outlet />
			<PreFooter />
			<Footer />
		</div>
	);
}

export default App;
