import s from "./style.module.css"; // Import Footer CSS file
import image1 from "../../assets/about.png";

export function AboutContainer() {
	return (
		<div className={`${s.container1}`}>
			<div className={`container`}>
				<div id="container1" className={` `}>
					<div className="row">
						<img src={image1} alt="About" className={`${s.image1} col`} />
					</div>
					<div>
						<h1>VelocyTax - Bid, Ride, Save – Every Time!</h1>
					</div>
					<div className={`${s.textContainer}`}>
						<div className={`${s.text}`}>
							Velocytax is revolutionizing the ride-sharing industry with its
							pioneering bidding system, putting you in control of your rides
							like never before. With Velocytax, you can bid, ride, and save –
							every time!
						</div>
						<div className={`${s.text}`}>
							Gone are the days of fixed fares and uncertainty about costs. With
							our innovative approach, you choose your ride and set your price.
							Whether commuting, running errands, or going out, Velocytax offers
							flexibility to fit your budget. Say goodbye to overpriced rides
							and unpredictable fares.
						</div>
						<div className={`${s.text}`}>
							Embrace the freedom to ride your way with Velocytax – join the
							revolution today and take control of your journey!
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
