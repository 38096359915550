import s from "./style.module.css";
import img1 from "../../assets/home-img-7.png";
import img2 from "../../assets/home-img-8.png";
import img3 from "../../assets/home-img-9.png";
import img4 from "../../assets/home-img-10.png";

export function HomeContainer7() {
	return (
		<div className={`${s.container7}`}>
			<div className={`container`}>
				<div className={`d-flex justify-content-center `}>
					<h3>VelocyTax - Bid, Ride, Save – Every Time!</h3>
				</div>
				<div className={`${s.text}`}>
					Pioneering a new era in ride-sharing and instant lifts. With our
					innovative bidding system, you're in control – choose your ride and
					set your price. Say goodbye to fixed fares and embrace the freedom to
					ride your way, every day. Join the revolution now!
				</div>
				<div className={`row  justify-content-center py-4`}>
					<div className={`col `}>
						<div className={`${s.imgContainer}`}>
							<img src={img1} alt="Instant Bokking" />
						</div>
						<div className={`${s.textContainer}`}>
							<div className={``}>
								<b>Instant Ride Booking</b>
							</div>
							<div className={``}>Need a lift right now? Book instantly!</div>
						</div>
					</div>
					<div className={`col  `}>
						<div className={`${s.imgContainer}`}>
							<img src={img2} alt="Ride Sharing" />
						</div>
						<div className={`${s.textContainer}`}>
							<div className={`${s.text1}`}>
								<b>Ride Sharing</b>
							</div>
							<div className={`${s.text2}`}>Share your ride, save money.</div>
						</div>
					</div>
					<div className={`col  `}>
						<div className={`${s.imgContainer}`}>
							<img src={img3} alt="Bid Price " />
						</div>
						<div className={`${s.textContainer}`}>
							<div className={`${s.text1}`}>
								<b>Bid Your Price</b>
							</div>
							<div className={`${s.text2}`}>
								Set your own price for every ride.
							</div>
						</div>
					</div>
					<div className={`col  `}>
						<div className={`${s.imgContainer}`}>
							<img src={img4} alt="Cost Saving " />
						</div>
						<div className={`${s.textContainer}`}>
							<div className={`${s.text1}`}>
								<b>Transparent Cost</b>
							</div>
							<div className={`${s.text2}`}>
								Know exactly what you're paying
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
