import s from "./style.module.css"; // Import Footer CSS file
import logo from "../../assets/logo.png";
import fb from "../../assets/fb.png";
import twitter from "../../assets/twitter.png";
import insta from "../../assets/insta.png";
import linkedIn from "../../assets/linkedIn.png";
// import googlePlayImg from "../../assets/google_play_img.png";
// import appStoreImg from "../../assets/app_store_img.png";

export function PreFooter() {
	return (
		<div className={`${s.preFooter}`}>
			<div className={` container`}>
				<div className="row">
					<div className="col-md-3 col-sm-6 p-0">
						<a className={`navbar-brand`} href="/">
							<img src={logo} alt="VelocityTax Logo " width="40" height="40" />
							<b>
								<span className={`${s.text} ps-2`}>VelocyTax</span>
							</b>
						</a>
					</div>
					{/* <div className="col-3">
						<ul id={s["ul"]} className={`${s.ul} pt-2`}>
							<li>
								<h5>
									<b>Services</b>
								</h5>
							</li>
							<li>Ride with us</li>
							<li>Drive with us</li>
							<li>Gift a Lift to others</li>
						</ul>
					</div> */}
					<div className="col-md-2 col-sm-6">
						<ul id={s["ul"]} className={`${s.ul} pt-2`}>
							<li>
								<h5>
									<b>Company</b>
								</h5>
							</li>
							<li>About Us</li>
							<li>Contact Us</li>
							<li>Support</li>
						</ul>
					</div>
					<div className="col-md-5 col-sm-6 ">
						<div className={`${s.center} `}>
							<b>We are coming with more features you want!</b>
						</div>
						{/* <ul id={s["ul"]} className={`${s.ul} pt-2`}>
							<li>
								<h5>
									<b>Contact Us</b>
								</h5>
							</li>
							<li>Support@velocytax.in</li>
						</ul> */}
					</div>
					<div className="col-md-2 col-sm-6">
						<h5 className="pt-2">
							<b>Contact Us</b>
						</h5>
						<p>Support@velocytax.in</p>
						<span className="me-1">
							{/* <img src={googlePlayImg} alt="Google Play " /> */}
						</span>
						{/* <img src={appStoreImg} alt="App Store " /> */}
						<div className="">
							<div className="row">
								<h5>
									<b className="col">Follow Us on</b>
								</h5>
							</div>
							<div className="row">
								<div className="col">
									<img
										src={fb}
										alt="Facebook"
										width={20}
										height={20}
										className={` ${s.image}`}
									/>
								</div>
								<div className="col">
									<img
										src={twitter}
										alt="twitter"
										width={20}
										height={20}
										className={` ${s.image}`}
									/>
								</div>
								<div className="col">
									<img
										src={insta}
										alt="Inatagram"
										width={20}
										height={20}
										className={` ${s.image}`}
									/>
								</div>
								<div className="col">
									<img
										src={linkedIn}
										alt="LinkedIn"
										width={20}
										height={20}
										className={` ${s.image}`}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
