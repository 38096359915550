import s from "./style.module.css"; // Import Footer CSS file

export function Footer() {
	return (
		<div className={`${s.footer}`}>
			<div className={`d-flex justify-content-center  ${s.content}`}>
				<div className="col-md-4 text-center py-1">
					&#169; 2024 VelocityTax.in All rights reserved.
				</div>
				{/* <div className="col-md-1 text-center py-1">
					<u>FAQ</u>
				</div> */}
				<div className="col-md-2 text-center py-1">
					<u>Privacy Policy </u>
				</div>
				<div className="col-md-2 text-center py-1">
					<u>Terms of Service</u>
				</div>
			</div>
		</div>
	);
}
