// import s from "./style.module.css";
import { HomeContainer1 } from "../home_container_1/home_container_1";
import { HomeContainer2 } from "../home_container_2/home_container_2";
import { HomeContainer3 } from "../home_container_3/home_container_3";
import { HomeContainer4 } from "../home_container_4/home_container_4";
import { HomeContainer6 } from "../home_container_6/home_container_6";
import { HomeContainer7 } from "../home_container_7/home_container_7";
export function Home() {
	return (
		<div>
			<HomeContainer1 />
			<HomeContainer7 />
			<HomeContainer2 />
			<HomeContainer3 />
			<HomeContainer4 />
			<HomeContainer6 />
		</div>
	);
}
