import s from "./style.module.css";
import img1 from "../../assets/chat.png";
import img2 from "../../assets/car.png";
import img3 from "../../assets/secure.png";

export function HomeContainer6() {
	return (
		<div className={`${s.container6} `}>
			<div className={`container pt-3`}>
				<div className={` ${s.headText}`}>
					<h1>We're here for you every step of the path.</h1>
				</div>
				<div id="container6" className={`row  justify-content-center py-4`}>
					<div className={`col-md py-2 `}>
						<div className={`${s.imgContainer}`}>
							<img src={img1} alt="Support" />
						</div>
						<div className={`${s.textContainer}`}>
							<div className={`${s.text1}`}>
								<b>Available 24/7 for You</b>
							</div>
							<div className={`${s.text2}`}>
								Our team is available around the clock to answer any questions
								you may have via email or social media. Additionally, you can
								engage in live chat directly with our experienced members.
							</div>
						</div>
					</div>
					<div className={`col-md py-2  `}>
						<div className={`${s.imgContainer}`}>
							<img src={img2} alt="Benefit" />
						</div>
						<div className={`${s.textContainer}`}>
							<div className={`${s.text1}`}>
								<b>Your Reliable Partner on the Road</b>
							</div>
							<div className={`${s.text2}`}>
								Commission-free bookings for rides up to 7 KMs and zero
								commission on ride sharing up to 149 KMs. Join VelocyTax and
								enjoy cost-effective travel.
							</div>
						</div>
					</div>
					<div className={`col-md py-2  `}>
						<div className={`${s.imgContainer}`}>
							<img src={img3} alt="Secure " />
						</div>
						<div className={`${s.textContainer}`}>
							<div className={`${s.text1}`}>
								<b>100% secure Information</b>
							</div>
							<div className={`${s.text2}`}>
								Our team ensures your data's absolute confidentiality with
								rigorous monitoring, secure navigation, and encrypted protocols,
								guaranteeing utmost protection at all times.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
