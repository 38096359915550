import s from "./style.module.css";
import homeImage3 from "../../assets/home-img-3.png";

export function HomeContainer4() {
	return (
		<div className={`${s.container4}`}>
			<div className={`container`}>
				<div id="container4" className={`row  justify-content-md-center `}>
					<div id="block1" className={` col-md-6 col-sm-12 ${s.block2}`}>
						<img
							src={homeImage3}
							alt="VelocityTax app "
							className={`${s.image1} `}
						></img>
					</div>
					<div id="block2" className={`col-md-6 ${s.text}`}>
						<div className={``}>
							<h1>Ready to Hit the Road in Your Vehicle?</h1>
						</div>
						<div className={``}>
							Gear up for your journey ahead! Whether by car, bike or auto.
							start hosting your ride now with our convenient booking platform.
							Join us!
						</div>
						<div className={`${s.text1}`}>
							<div>-We are comming Soon</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
