import s from "./style.module.css";
import img1 from "../../assets/rupee-img.png";
import img2 from "../../assets/thumb-img.png";
import img3 from "../../assets/pointer-img.png";

export function HomeContainer3() {
	return (
		<div className={`${s.container3}`}>
			<div className={`container pt-3`}>
				<div className={` ${s.headText}`}>
					<h1>SmartTravel: Your Seamless Journey Begins Here!</h1>
				</div>
				<div id="container3" className={`row  justify-content-left py-4`}>
					<div className={`col-md py-2 `}>
						<div className={`${s.textContainer}`}>
							<img src={img1} alt="Rupees" />
						</div>
						<div className={`${s.textContainer}`}>
							<div className={`${s.text1}`}>
								<b>Find Your Ideal Pooling Choice, Affordable!</b>
							</div>
							<div className={`${s.text2}`}>
								Whether it's by bike, car, or bus, find your ideal ride among
								our diverse options with VelocyTax.
							</div>
						</div>
					</div>
					<div className={`col-md py-2 `}>
						<div className={`${s.textContainer}`}>
							<img src={img2} alt="Confidently" />
						</div>
						<div className={`${s.textContainer}`}>
							<div className={`${s.text1}`}>
								<b>Travel Confidently: Know Your Travel Partners</b>
							</div>
							<div className={`${s.text2}`}>
								We verify profiles and IDs, ensuring secure bookings and trusted
								companionship for a worry-free journey.
							</div>
						</div>
					</div>
					<div className={`col-md py-2 `}>
						<div className={`${s.textContainer}`}>
							<img src={img3} alt="Seamless " />
						</div>
						<div className={`${s.textContainer}`}>
							<div className={`${s.text1}`}>
								<b>Seamless Travel: Scroll, Click, Tap, and Go!</b>
							</div>
							<div className={`${s.text2}`}>
								Experience hassle-free ride booking with our user-friendly app,
								powered by cutting-edge technology for quick and easy
								reservations.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
